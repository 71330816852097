<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- filteres Area -->
    <!-- ************************************************************************************ -->
    <MetricFilterBox
      :defaultFilterData="metrics.states"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      :clearBtnShowen="metricsList.length > 0"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            v-if="metric.key != 'orderby'"
            :is="metric.compName"
            v-model="filters[metric.key]"
            :state="metric.displayedData ? metric.displayedData : filters.state"
            @change="filtersChanged(metric.key, $event)"
            @enterClicked="filterEnterClicked(metric.key, filters[metric.key])"
            :meta="metric.meta ? metric.meta : null"
          ></component>
          <component
            v-else
            :is="metric.compName"
            v-model="orderby"
            @change="orderChanged"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <div class="u-display-flex u-justify-content--end u-bottom-margin--2x">
      <el-button
        size="mini"
        icon="el-icon-bottom"
        type="info"
        plain
        :disabled="exportSheetDisableFlag"
        @click="exportSheetClicked"
      >
        {{ $t("GlobalExportFileButtonLabel") }}
      </el-button>

      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>
    <!-- ************************************************************************************ -->
    <!-- table Area -->
    <!-- ************************************************************************************ -->
    <div class="c-distributers-body">
      <el-table
        :data="transactionsList"
        @row-click="viewTransactionsDetails"
        header-cell-class-name="table-header"
        style="width: 100%; border-spacing: 0 1em"
        row-class-name="table-row"
      >
        <el-table-column type="expand" width="40">
          <template slot-scope="props">
            <div v-if="!props.row.receipts.length">
              <h1 v-t="'TransactionPageEmptyTransactionTableMessage'">j</h1>
            </div>
            <el-row :gutter="20">
              <el-col
                :span="8"
                v-for="(receipt, index) in props.row.receipts"
                :key="index"
                :offset="0"
              >
                <div class="u-bottom-margin--2x recipt-card">
                  <div class="recipt-header">
                    <div class="title u-font--xlarge u-font--bold">
                      {{ receipt.name }}
                    </div>
                    <div :class="`${recieptClassName(receipt)} status`">
                      {{ receipt.status }}
                    </div>
                  </div>
                  <div class="recipt-body">
                    <div>
                      {{ $t("TransactionPageReceiptPriceDifferenceLabel") }}
                      <span class="u-font--bold">
                        {{ receipt.discountAmount }}</span
                      >
                    </div>
                    <div>
                      {{ $t("TransactionPageReceiptNumberOfItemsLabel") }}

                      <span class="u-font--bold">
                        {{ receipt.numberOfItems }}</span
                      >
                    </div>
                    <div>
                      {{ $t("TransactionPageReceiptTotalLabel") }}

                      <span class="u-font--bold"> {{ receipt.total }}</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          :label="$t('TransactionPageOrderIdColumnTitle')"
          width="90"
        >
          <template slot-scope="scope">
            {{ scope.row.id }}
            <i
              @click.stop="copyText(scope.row.id)"
              class="el-icon-document-copy"
            ></i>
            <br />
            <template v-if="scope.row.section">
              <span class="small-text"> {{ scope.row.section.title }} </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderId"
          :label="$t('TransactionPageOrderNumberColumnTitle')"
          width="80"
        >
        </el-table-column>
        <el-table-column
          :label="$t('TransactionPageOrderStoreOwnerColumnTitle')"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.storeName }}
              <i
                @click.stop="copyText(scope.row.storeName)"
                class="el-icon-document-copy"
              ></i>
            </div>
            <div class="small-text">
              # {{ scope.row.userId }}
              <i
                @click.stop="copyText(scope.row.userId)"
                class="el-icon-document-copy"
              ></i>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="createdAt"
          :label="$t('TransactionPageDateColumnTitle')"
          width="180"
        >
          <template slot-scope="prop">
            <div>{{ prop.row.createdAt | moment("DD/MM/YYYY") }}</div>
            <div class="small-text">
              {{ prop.row.createdAt | moment("h:mm a") }}
              <!-- {{ prop.row.createdAt | moment("from") }} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="`Order Value`" width="130">
          <template slot-scope="scope">
            <div>{{ scope.row.amount }} EGP</div>
            <div class="small-text" v-if="scope.row.offers_amount_percentage">
              {{ scope.row.offers_amount_percentage }}%
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="`# of Items`" width="130">
          <template slot-scope="scope">
            <div>
              {{ scope.row.itemsCount }} {{ $t("TransactionPageItemsLabel") }}
            </div>
            <div class="small-text" v-if="scope.row.offered_items_percentage">
              {{ scope.row.offered_items_percentage }} %
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TransactionPageAreaColumnTitle')"
          width="210"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.area }}
              <i
                @click.stop="copyText(scope.row.area)"
                class="el-icon-document-copy"
              ></i>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="area" label="Area" width="210">
        </el-table-column> -->
        <el-table-column
          prop="method"
          :label="$t('TransactionPageTypeColumnTitle')"
          width="110"
        >
          <template slot-scope="scope">
            <div style="text-transform: capitalize">{{ scope.row.method }}</div>
            <template v-if="scope.row.method == 'credit' && scope.row.bundle">
              <div class="small-text u-font--xsmall">
                {{ $t("TransactionPagePeriodLabel") }}: <br />
                {{ scope.row.bundle.paybackDuration }}
                {{
                  scope.row.bundle.premiumPercentage
                    ? ` - ${(scope.row.bundle.premiumPercentage * 100)?.toFixed(
                        2
                      )} %`
                    : ""
                }}
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="state"
          :label="$t('TransactionPageStatusColumnTitle')"
          width="170"
        >
          <template slot-scope="scope">
            <!-- <div
              style="
                text-transform: capitalize;
                display: flex;
                align-items: center;
              "
            >
              <div
                style="height: 10px; width: 10px; border-radius: 50%"
                :class="`${tableRowClassName(scope.row)} u-right-margin--1x`"
              ></div>
              {{ scope.row.state }}
            </div> -->
            <div
              style="
                display: flex;
                align-items: center;
                flex-direction: row;
                text-transform: capitalize;
              "
            >
              <div
                style="height: 10px; width: 10px; border-radius: 50%"
                :style="{
                  'background-color': scope.row.statusColor
                    ? scope.row.statusColor
                    : '',
                }"
                :class="`
                ${scope.row.statusColor ? '' : tableRowClassName(scope.row)}
                ${
                  $store.state.isRTL
                    ? 'u-left-margin--1x'
                    : 'u-right-margin--1x'
                }`"
              ></div>
              {{ scope.row.status }}
              <emoji
                v-if="scope.row.promocode"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--1x'
                    : 'u-left-margin--1x'
                "
                style="line-height: 1"
                :emoji="{ id: 'money_with_wings', skin: 3 }"
                :size="16"
                title="Promocode"
              />
              <emoji
                v-if="scope.row.isAutoAssigned && scope.row.isAutoAssigned == 1"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--1x'
                    : 'u-left-margin--1x'
                "
                style="line-height: 1"
                :emoji="{ id: 'rocket', skin: 1 }"
                :size="16"
                title="Auto Assigned"
              />
              <emoji
                v-if="scope.row.isFatura && scope.row.isFatura == 1"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--1x'
                    : 'u-left-margin--1x'
                "
                style="line-height: 1"
                :emoji="{ id: 'star', skin: 1 }"
                :size="16"
                title="Fatura Product"
              />
              <emoji
                v-if="scope.row.hasTargets"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--1x'
                    : 'u-left-margin--1x'
                "
                style="line-height: 1"
                :emoji="{ id: 'dart', skin: 1 }"
                :size="16"
                title="Targets"
              />
              <emoji
                v-if="scope.row.isFirstInteraction"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--1x'
                    : 'u-left-margin--1x'
                "
                style="line-height: 1"
                :emoji="{ id: 'one' }"
                :size="16"
                title="First Interaction"
              />
              <span
                title="Pending Operation"
                v-if="scope.row.pendingOperationRequest"
                style="margin-left: 6px"
                >🧨</span
              >

              <img
                v-if="scope.row?.cashbackRedemptionAmount"
                src="../assets/Cash.png"
                class="cash-logo"
                alt=""
              />
            </div>
          </template>
        </el-table-column>
        <!-- TODO uncomment internal notes -->
        <!-- <el-table-column
          label="Internal Notes"
          prop="userNotes"
        ></el-table-column> -->
        <!-- <el-table-column
          :label="$t('TransactionPageRetailerNotesColumnTitle')"
          prop="notes"
        ></el-table-column> -->
        <el-table-column
          :label="$t('TransactionPageUserNotesColumnTitle')"
          prop="userNotes"
        ></el-table-column>
      </el-table>
      <!-- ************************* -->
      <!-- pagination -->
      <!-- ************************ -->

      <div class="paginateAreaWrapper" v-if="loadMore">
        <el-button type="primary" size="medium" @click="loadMoreClicked"
          >Load More</el-button
        >
      </div>

      <!-- <div class="u-display-flex u-justify-content--center u-top-padding--2x">
        <el-pagination
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :total="pagination.totalResultsCount"
          :current-page="paginationCurrentPage"
          :page-size="25"
          @current-change="handlePagination"
        ></el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { transactions } from "@/services";
import metricbox from "@/components/transaction/metricbox";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import { transactionMetrics } from "../components/transaction/metricboxComponents";
import { utils } from "../utils";
import { mapGetters, mapActions } from "vuex";
import { Emoji } from "emoji-mart-vue";

export default {
  name: "Transaction",
  components: {
    MetricFilterBox,
    metricbox,
    ...transactionMetrics,
    Emoji,
  },

  data() {
    return {
      filters: {
        state: "All",
      },
      metricsList: [],

      metrics: {
        states: [
          {
            key: "phone",
            label: "TransactionPagePhoneFilterLabel",
            compName: "phoneMetric",
          },
          {
            key: "order_id",
            label: "TransactionPageOrderIdFilterLabel",
            compName: "orderIDMetric",
          },
          {
            key: "providers",
            label: "TransactionPageProvidersFilterLabel",
            compName: "providersMetric",
          },
          {
            key: "provider",
            label: "TransactionPageProviderTypeFilterLabel",
            compName: "providerTypeMetric",
            meta: { multiple: true },
          },
          {
            key: "receiptStatus",
            label: "TransactionPageReceiptStateFilterLabel",

            compName: "receiptStatusMetric",
            meta: { multiple: true },
          },
          {
            key: "regions",
            label: "TransactionPageRegionFilterLabel",
            compName: "regionMetric",
          },
          {
            key: "start_date",
            label: "TransactionPageStartDateFilterLabel",
            compName: "startDateMetric",
          },
          {
            key: "end_date",
            label: "TransactionPageEndDateFilterLabel",
            compName: "endDateMetric",
          },
          {
            key: "state",
            label: "TransactionPageTransactionStateFilterLabel",
            compName: "stateMetric",
            meta: { multiple: true },
          },
          {
            key: "store_name",
            label: "TransactionPageStoreNameIDFilterLabel",
            compName: "storeNameID",
          },
          {
            key: "method",
            label: "TransactionPageMethodFilterLabel",
            compName: "typeMetric",
          },
          // {
          //   key: "order_number",
          //   label: "TransactionPageTransactionNumberFilterLabel",
          //   compName: "TransNumMetric",
          // },
          // {
          //   key: "orderby",
          //   label: "TransactionPageOrderByFilterLabel",
          //   compName: "orderbyMetric",
          //   changeFn: "orderChanged",
          // },
          {
            key: "has_promocode",
            label: "TransactionPageHasPromocodeFilterLabel",

            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: this.$t("TransactionPageYesLabel"),
              },
              {
                value: this.$t("TransactionPageNoLabel"),
              },
            ],
          },
          {
            key: "pendingOperationRequest",
            label: "Has Pending Operation Request",

            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: this.$t("TransactionPageYesLabel"),
              },
              {
                value: this.$t("TransactionPageNoLabel"),
              },
            ],
          },
          {
            key: "promocode",
            label: "TransactionPagepromocodeFilterLabel",
            compName: "stringMetric",
          },
          {
            key: "isAutoAssigned",
            label: "TransactionPagetransactionTypFilterLabele",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: this.$t("TransactionPageTransactionTypeAllLabel"),
              },
              {
                value: this.$t(
                  "TransactionPageTransactionTypeAutoAssignedLabel"
                ),
              },
              {
                value: this.$t(
                  "TransactionPageTransactionTypeNonAutoAssignedLabel"
                ),
              },
            ],
          },
          {
            key: "fatura",
            label: "TransactionPageHasFaturaProductsLabel",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: this.$t("TransactionPageYesLabel"),
              },
              {
                value: this.$t("TransactionPageNoLabel"),
              },
            ],
          },
          {
            key: "sort",
            label: "TransactionPageSortByLabel",
            compName: "sortMetric",
          },
          {
            key: "addressLandmark",
            label: "TransactionDetailsPageInfoLandmarkLabel",
            compName: "stringMetric",
          },

          {
            key: "tNumberInterval",
            label: "TransactionPageFilterLabelT",
            compName: "tMetric",
          },
          {
            key: "receiptId",
            label: "TransactionPageReceiptIdFilterLabel",
            compName: "stringMetric",
          },
          {
            key: "internal_note",
            label: "TRX Notes",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: "TRX With Notes",
              },
              {
                value: "TRX Without Notes",
              },
            ],
          },
          {
            key: "hasTargets",
            label: "Has Targets",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: "Yes",
              },
              {
                value: "No",
              },
            ],
          },
          {
            key: "isFirstInteraction",
            label: "First Interaction",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: "Yes",
              },
              {
                value: "No",
              },
            ],
          },
          {
            key: "industries",
            label: "Industry Type",
            compName: "IndustryMetric",
            meta: { multiple: true },
          },
        ],
      },
      orderby: "",
      transactionsList: [],
      lastId: undefined,
      loadMore: true,
      pagination: {},
    };
  },
  async mounted() {
    let filtersSession = sessionStorage.getItem("filter");
    if (filtersSession) {
      filtersSession = JSON.parse(filtersSession);
      if (filtersSession.transactions) {
        const currentTransactionsFilter = filtersSession.transactions;
        if (currentTransactionsFilter && currentTransactionsFilter.filters)
          this.filters = currentTransactionsFilter.filters;
        if (currentTransactionsFilter && currentTransactionsFilter.orderby)
          this.orderby = currentTransactionsFilter.orderby;
      }
    }
    let metricsSession = localStorage.getItem("metrics");
    if (metricsSession) {
      this.metricsList = JSON.parse(metricsSession);
    }
    await this.getTransacitions(this.lastId, this.filters, this.orderby);
  },

  computed: {
    // currentPage() {
    //   return this.$route.query.page;
    // },
    ...mapGetters({
      lookups: "getAllLookups",
      filtersData: "getTransactionFilters",
    }),

    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    exportSheetDisableFlag() {
      try {
        let filtersCount = 0;
        for (const property in this.filters) {
          if (
            (this.filters[property] !== undefined &&
              property !== "sort" &&
              property !== "state" &&
              this.filters[property].length !== 0) ||
            (property == "state" && this.filters[property] !== "All")
          ) {
            filtersCount++;
          }
        }
        let returnedValue = true;
        if (filtersCount > 0) returnedValue = false;
        return returnedValue;
      } catch (error) {
        console.log("exportFlagError :>> ", error);
        return true;
      }
    },
  },
  filters: {
    capitalize: (val) => {
      return _.capitalize(val);
    },
  },
  methods: {
    loadMoreClicked() {
      if (this.transactionsList && this.transactionsList.length > 0) {
        const lastId =
          this.transactionsList[this.transactionsList.length - 1].id;
        this.lastId = lastId;
        this.getTransacitions(this.lastId, this.filters);
      }
    },
    ...mapActions([
      "fetchLookups",
      "setTransactionFilters",
      "setSectionslookup",
    ]),
    copyText(text) {
      utils.copyText(text);
    },
    //**************************************************************************************** */
    //filters Area
    //**************************************************************************************** */

    clearFilters() {
      this.filters = {
        state: "All",
        regions: [],
      };
      this.orderby = "";
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.transactions = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metrics", []);
      this.getTransacitions(this.lastId, { ...this.filters });
    },
    closeMetric(metric) {
      if (metric.key == "orderby") {
        this.orderby = "";
      }
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem("metrics", JSON.stringify(this.metricsList));
      //fix lodash with reactivity issue
      this.metricsList = [...this.metricsList];
    },
    saveMetircs() {
      localStorage.setItem("metrics", JSON.stringify(this.metricsList));
    },
    regionsChanged(value) {
      this.filters.regions = value;

      this.filtersChanged("region", value);
    },

    //**************************************************************************************** */
    //Transacitn Area
    //**************************************************************************************** */

    getTransacitions(lastId, filters) {
      if (filters.state == "pending") {
        filters.providers = undefined;
        filters.receiptStatus = undefined;
      }
      return transactions
        .getAllTransactions(lastId, { ...filters }, this.orderby)
        .then((response) => {
          if (
            response.transactionsList.length > 0 &&
            response.transactionsList[response.transactionsList.length - 1]
              ?.id !== lastId
          ) {
            this.transactionsList = [
              ...this.transactionsList,
              ...response.transactionsList,
            ];
            this.pagination = response.pagination;
            this.isFetchTransactionsList = false;
            this.loadMore = true;
          } else if (
            response?.transactionsList[response?.transactionsList?.length - 1]
              ?.id == lastId ||
            response.transactionsList.length === 0
          ) {
            this.loadMore = false;
          }

          return response;
        })
        .catch((e) => {
          const errorMessage = this.errorMessageExtractor(e);
          this.popupMessageWrapper(errorMessage, "error", 3500);
        });
    },
    handlePagination(val) {
      // const pageLoading = this.$loading();
      // transactions
      //   .getAllTransactions(val, { ...this.filters }, this.orderby)
      //   .then((response) => {
      //     this.transactionsList = response.transactionsList;
      //     this.pagination = response.pagination;
      //     this.isFetchTransactionsList = false;
      //     this.$router.push({
      //       query: {
      //         page: response.pagination.nextPage
      //           ? response.pagination.nextPage - 1
      //           : response.pagination.totalPages,
      //       },
      //     });
      //   })
      //   .finally(() => {
      //     pageLoading.close();
      //   });
      this.$router.push({
        query: {
          page: val,
        },
      });
    },
    viewTransactionsDetails(row) {
      this.$store.commit("setTransactionId", row.id);
      this.$router.push({
        name: "newTransactionsDetails",
        params: { id: row.id },
      });
    },
    orderChanged(value) {
      this.orderby = value;
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.transactions = {
        filters: { ...this.filters },
        orderby: this.orderby,
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.lastId = undefined;
      this.getTransacitions(this.lastId, { ...this.filters });

      // this.$router.push({
      //   query: {
      //     page: 1,
      //   },
      // });
    },
    filtersChanged(key, value) {
      if (_.isEmpty(value)) {
        this.filters[key] = undefined;
      }
      if (key == "store_name") {
        this.filters.user_id = this.filters.store_name;
      }
      if (key == "industries") {
        this.filters.industries = value;
      }
      if (key == "state" && value == "pending") {
        this.filters.receiptStatus = undefined;
        this.filters.providers = undefined;
      }
      let sessionFilter = sessionStorage.getItem("filter")
        ? JSON.parse(sessionStorage.getItem("filter"))
        : {};
      sessionFilter.transactions = {
        filters: { ...this.filters },
        orderby: this.orderby,
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));

      // if (this.$route.fullPath !== "/transactions?page=1") {
      //   this.$router.push({
      //     query: {
      //       page: 1,
      //     },
      //   });
      //   return;
      // }

      // if (this.lastId) {
      //   this.transactionsList = [];
      //   this.lastId = undefined;
      //   this.getTransacitions(this.lastId, { ...this.filters }, this.orderby);
      //   return;
      // }

      this.transactionsList = [];
      this.lastId = undefined;

      this.getTransacitions(this.lastId, { ...this.filters }, this.orderby);
    },
    tableRowClassName(row) {
      if (row.state === "in progress") {
        return "u-bg--orange-dark";
      } else if (row.state === "pending") {
        return "u-bg--red-light";
      } else if (row.state === "complete") {
        return "u-bg--green-light";
      } else if (row.state === "unfulfilled") {
        return "u-bg--red-light";
      }
      return "";
    },
    recieptClassName(receipt) {
      if (receipt.status === "inprogress") {
        return "u-bg--orange-dark";
      } else if (receipt.status === "pending") {
        return "u-bg--red-light";
      } else if (receipt.status === "intransit") {
        return "u-bg--yellow-light";
      } else if (receipt.status === "cancelled") {
        return "u-bg--red";
      } else if (receipt.status === "rejected") {
        return "u-bg--red";
      } else if (receipt.status === "complete") {
        return "u-bg--green-light";
      }
      return "";
    },
    async exportSheetClicked() {
      try {
        this.$loading();
        const response = await transactions.exportTransactionsSheet(
          this.filters
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = `Transactions.xlsx`;
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.popupMessageWrapper(
            this.$t("GlobalFileDownloadSuccess"),
            "success",
            4000
          );
        }
        this.$loading().close();
      } catch (error) {
        const errorMessage = this.errorMessageExtractor(error);
        this.$loading().close();
        this.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    errorMessageExtractor(errorObject) {
      try {
        const errorMessageExists =
          errorObject.response && errorObject.response.data;
        let finalErrorMessage = this.$t("GlobalErrorMessage");
        if (errorMessageExists) {
          if (errorObject.response.data.error) {
            finalErrorMessage = errorObject.response.data.error;
          } else {
            const currentErrorData = errorObject.response.data;
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(currentErrorData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.error) finalErrorMessage = parsedResponse.error;
            if (parsedResponse.message)
              finalErrorMessage = parsedResponse.message;
          }
        }
        return finalErrorMessage;
      } catch (error) {
        console.log("parseError :>> ", error);
        return "message";
      }
    },
    filterEnterClicked(key, value) {
      this.filtersChanged(key, value);
    },
  },
  watch: {
    // $route(to) {
    //   this.getTransacitions(to.query.page, this.filters);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/wrench-kit/settings/_settings.global.scss";
$primary-color: #405793;
// ********************************************************************************************
// ********************************************************************************************
// ********************************************************************************************
.paginateAreaWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
}
.paginateAreaWrapper button {
  margin-top: 12px;
}
::v-deep .table-header {
  color: #8ea6c1;
}
::v-deep .table-row {
  font-weight: 900;
  color: #405793;
  i {
    color: #fccd13;
    transition: all 0.25s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }
  cursor: pointer;
  background-color: #ffffff;
  margin-bottom: 1rem;
  border-radius: 1px;
  box-shadow: 0 0 32px 0 rgba(12, 10, 61, 0.04);
  .small-text {
    font-weight: 500;
    color: #abb5d0;
  }
}
// ********************************************************************************************
// ********************************************************************************************
// ********************************************************************************************
.recipt-card {
  color: $primary-color;

  .recipt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    background-color: #f6f7fa;
    margin-bottom: 0.25rem;

    .status {
      color: #ffffff;
      border-radius: 9px;
      background-color: #ff595e;
      padding: 1px 12px;
    }
  }
  .recipt-body {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 8px 15px;
    background-color: #f6f7fa;
  }
}
.cash-logo {
  margin-left: 0.25rem;

  height: 20px;
  width: 20px;
  object-fit: cover;
}
</style>
